import { render, staticRenderFns } from "./RecentWorks.vue?vue&type=template&id=51192ac4&"
import script from "./RecentWorks.vue?vue&type=script&lang=js&"
export * from "./RecentWorks.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VThemeProvider } from 'vuetify/lib/components/VThemeProvider';
installComponents(component, {VBtn,VCol,VContainer,VItem,VItemGroup,VResponsive,VRow,VThemeProvider})
